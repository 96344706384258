<template>
  <div style="max-width:100%; width: 100%;height:100%;overflow-x: auto;" class="d-flex flex-column py-3">
    <LeftTopPanelTitle text="Cart" icon="shopping-cart" />
    <!-- card previews -->
    <div
      v-show="currentBasket.length > 0"
      ref="scrollContainer"
      style="width: 100%; overflow-x: auto;flex-grow: 1;"
    >
      <div style="width: fit-content;height: 100%;" class="d-flex p-3 mx-auto">
        <FadeList
          class="d-flex"
        >
          <div
            v-for="(basketItem, i) in basket"
            :key="i"
            class="d-flex align-items-center mx-2 mx-sm-3"
            style="height: 100%;position: relative;"
          >
            <CardPreviewFromProps
              class="mr-1 mr-sm-3 my-2"
              style="width: 250px; position: relative;"
              :hero="basketItem.Hero"
              :colour="basketItem.Colour"
              :sport="basketItem.Sport"
              :number="basketItem.Number"
              :first-initial="basketItem.FirstInitial"
              :second-initial="basketItem.SecondInitial"
            />
            <div
              class="d-flex align-items-center justify-content-center closeCircle"
              @click="deleteItemFromBasket(basketItem, i)"
            >
              <i class="fa fa-times" style="color:var(--pr-color);" />
            </div>
          </div>
        </FadeList>
      </div>
    </div>
    <span v-if="currentBasket.length === 0" class="text-white text-center mt-5">YOUR CART IS EMPTY</span>
    <div class="mx-auto">
      <mdb-btn
        v-if="currentBasket.length > 0"
        style="width:110px !important;font-size:0.8rem;padding:10px !important;"
        class="btn primary-btn btn-radius btn-sm d-md-none p-0 m-0"
        @click="checkout()"
      >
        CHECKOUT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import basketService from '@/api-services/basket.service'
import CardPreviewFromProps from '@/components/cardPreviews/cardPreviewFromProps.vue'
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'
import FadeList from '@/components/UI/fadeList.vue'

export default {
  name: 'MainBasket',
  components: { CardPreviewFromProps, LeftTopPanelTitle, FadeList },
  props: {
    fontSize: {
      type: String,
      default: '1rem'
    },
    iconFontSize: {
      type: String,
      default: '1rem'
    }
  },
  data () {
    return {
      basket: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['currentBasket'])
  },
  mounted () {
    this.addScrollEvent()
  },
  beforeDestroy () {
    this.basket = []
  },
  created () {
    this.$watch('$store.state.currentBasket', () => {
      this.handleBasketChange()
    }, { immediate: true, deep: true })
  },
  methods: {
    handleBasketChange () {
      this.basket = []
      this.$nextTick(() => {
        setTimeout(() => {
          this.basket = [...this.currentBasket]
        }, 300)
      })
    },
    deleteItemFromBasket (basketItem, index) {
      // checks for a basketorderId - means it is stored backend - so will either delete both front and back or just front (if anonymous)
      this.loading = true
      this.basket = []
      if (basketItem.BasketOrdersId) {
        basketService.removeFromBasket(basketItem.BasketOrdersId).then((res) => {
          if (res.data === true) {
            this.$store.commit('removeItemFromBasket', index)
          }
          this.loading = false
        })
      } else {
        this.$store.commit('removeItemFromBasket', index)
      }
      this.loading = false
      // if basket empty reload
      if (this.currentBasket.length === 0) {
        this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
      }
    },
    checkout () {
      this.$store.commit('storePanelViewedOnMobile', 'Right')
      this.$router.push('/basket/checkout').catch(() => {})
    },
    addScrollEvent () {
      const scrollContainer = this.$refs.scrollContainer
      scrollContainer.addEventListener("wheel", (evt) => {
        evt.preventDefault()
        scrollContainer.scrollLeft += evt.deltaY
      })
    }
  }
}
</script>

<style scoped>
.closeCircle {
  border-radius: 50%;
  background:var(--bg-color);
  border: var(--pr-color) solid 3px;
  position: absolute;
  width: 30px;
  height:30px;
  cursor:pointer;
  right: 5px;
  top: -10px;
}
@media (max-width: 576px) {
  .closeCircle {
    right: -10px;
    top: -12px;
  }
}
</style>
