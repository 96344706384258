<template>
  <MainContainer>
    <template #leftPanelTop>
      <MainBasket />
    </template>

    <template #leftPanelButtons>
      <mdb-btn
        class="btn primary-btn btn-right btn-outline btn-radius btn-sm btn-md"
        @click="addAnother()"
      >
        {{ currentBasket.length > 0 ? 'ADD ANOTHER HERO' : 'ADD A HERO' }}
      </mdb-btn>
    </template>

    <template #rightPanel>
      <router-view />
    </template>
  </MainContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import MainContainer from '@/components/layout/mainContainer.vue'
import MainBasket from '@/components/basket/mainBasket'

export default {
  name: 'BasketContainer',
  components: {
    MainContainer, MainBasket
  },
  data () {
    return {
      prize: ''
    }
  },
  computed: {
    ...mapGetters(['currentBasket'])
  },
  methods: {
    addAnother () {
      this.$store.commit('storeClub', { ClubId: 0 })
      this.$store.commit('storePanelViewedOnMobile', 'Left')
      this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
      this.$store.commit('storeId', 0)
      this.$store.commit('storeHero', 0)
      this.$store.commit('storeSport', 0)
      this.$store.commit('storeColour', '#e1e1e1')
      this.$store.commit('storeNumber', 0)
      this.$store.commit('storeFirstInitial', '')
      this.$store.commit('storeSecondInitial', '')
    }
  }
}
</script>
